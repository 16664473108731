<template>
  <div
    @drop.prevent="_event => addDroppedFiles(_event)"
    @dragover.prevent="hightlightUploadBox = true"
    @dragexit.prevent="hightlightUploadBox = false"
    @dragleave.prevent="hightlightUploadBox = false"
    @dragend.prevent="hightlightUploadBox = false"
    :class="{hightlighted: hightlightUploadBox}"
    class="drop-area">
    <div v-if="!loading" class="large" style="padding: 0 20px;" align='center' @click="openUpload()">
      <h1 style="margin-top: 60px">
        <v-icon style='font-size:1em'>picture_as_pdf</v-icon>
      </h1>
      <h3>{{ $t('fileUpload.title') }}</h3>
      <span style='font-size: 12pt'>
        {{ $t('fileUpload.description') }}
      </span>
    </div>
    <p
      class="large"
      style="padding: 0 20px; margin-top: 100px"
      v-else
      align='center'
>
      <span>
        <v-progress-circular v-if='showProgess' color="primary" :value='progressValue'></v-progress-circular>
        <v-progress-circular v-else indeterminate color="primary"></v-progress-circular>
        <!-- <br/>
        <span v-if='showProgess'>Files processed : {{ progress.progress }} / {{ progress.length }}</span>
        <span v-else style='opacity:0'>.</span> -->
      </span>
    </p>
    <input
      :disabled="loading"
      :key="key"
      hidden
      :accept="allowedFiles"
      multiple
      @change="handleInputChange()"
      ref="fileUpload"
      type="file" >
  </div>
</template>

<script>
export default {
  name: 'DropArea',
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    progress: {
      type: Object
    }
  },
  data () {
    return {
      hightlightUploadBox: false,
      key: Date.now()
    }
  },
  computed: {
    allowedFiles () {
      return '.pdf'
    },
    showProgess () {
      return this.progress && this.progress.length && this.progress.length > 0 && this.progress.progress <= this.progress.length
    },
    progressValue () {
      if (this.showProgess) {
        return Math.round(this.progress.progress * 100 / this.progress.length)
      }
      return 0
    }
  },
  methods: {
    openUpload () {
      this.$refs.fileUpload.click()
    },

    addFiles (files) {
      if (this.loading) return
      files = [...files]
      this.$emit('input', files)
    },

    addDroppedFiles (event) {
      const files = event.dataTransfer.files
      if (files) {
        this.addFiles(files)
      }

      this.hightlightUploadBox = false
    },

    handleInputChange () {
      const files = this.$refs.fileUpload.files
      if (files) {
        this.addFiles(files)
      }

      // resets the input component
      this.key = Date.now()
    }
  }
}
</script>

<style scoped lang="scss">
  .drop-area {
    min-height: 235px;
    border: 5px dashed gray;
    background: lightgray;
    display: inline-flex;
    width: 100%;
    align-content: center;
    justify-content: center;

    &:hover {
      cursor: pointer;
    }

    &.hightlighted {
      border-color: green;

      span.upload-icon {
        background: green !important;
      }

      p, i {
        color: green;
      }
    }
  }

  .large {
    font-size: 20px;
    line-height: 31px;
    margin-bottom: 0;
    width: 100%;
    justify-content: center;

    ::v-deep i {
      font-size: 30px;
    }
  }
</style>
